import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _62762476 = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _be487f06 = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _5bedac74 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _2f8a2b4d = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _21bfbcbc = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _fbc96be8 = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _a6edf2aa = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _7e71aa0f = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _35c1e5f0 = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _7aca8a32 = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _4eb25738 = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _25cb8514 = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _d15e82e2 = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _e1c9fd4e = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _3e691646 = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _7bbbdf08 = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _3d9e9a60 = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _1bd62a83 = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _559e6cab = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _5a53b51c = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _e59e20ec = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _cc039776 = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _5f507fc0 = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _3413997a = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _00350f7a = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _66a5ce75 = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _348d7cfa = () => interopDefault(import('../pages/onboarding/money-purchase-annual-allowance.vue' /* webpackChunkName: "pages/onboarding/money-purchase-annual-allowance" */))
const _18746909 = () => interopDefault(import('../pages/onboarding/my-tax-liability.vue' /* webpackChunkName: "pages/onboarding/my-tax-liability" */))
const _42752fd0 = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _a57efad8 = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _5b923103 = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _05eb2cea = () => interopDefault(import('../pages/onboarding/pension-savings.vue' /* webpackChunkName: "pages/onboarding/pension-savings" */))
const _7185b62c = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _5bd859bc = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _4946e598 = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _6c2be8d4 = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _06012835 = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _6739660b = () => interopDefault(import('../pages/onboarding/tapered-allowance.vue' /* webpackChunkName: "pages/onboarding/tapered-allowance" */))
const _6e3a512e = () => interopDefault(import('../pages/onboarding/tax-charge-period.vue' /* webpackChunkName: "pages/onboarding/tax-charge-period" */))
const _1e290d1a = () => interopDefault(import('../pages/onboarding/tax-payment-methods.vue' /* webpackChunkName: "pages/onboarding/tax-payment-methods" */))
const _40fe75e3 = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _7de94e6c = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _9971ec9a = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _585b3da2 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _177400c6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _62762476,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _be487f06,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _5bedac74,
    name: "help"
  }, {
    path: "/verify-email",
    component: _2f8a2b4d,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _21bfbcbc,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _fbc96be8,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _a6edf2aa,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _7e71aa0f,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _35c1e5f0,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _7aca8a32,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _4eb25738,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _25cb8514,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _d15e82e2,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _e1c9fd4e,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _3e691646,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _7bbbdf08,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _3d9e9a60,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _1bd62a83,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _559e6cab,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _5a53b51c,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _e59e20ec,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _cc039776,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/employment-info",
    component: _5f507fc0,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/fund-selection",
    component: _3413997a,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _00350f7a,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _66a5ce75,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/money-purchase-annual-allowance",
    component: _348d7cfa,
    name: "onboarding-money-purchase-annual-allowance"
  }, {
    path: "/onboarding/my-tax-liability",
    component: _18746909,
    name: "onboarding-my-tax-liability"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _42752fd0,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _a57efad8,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _5b923103,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/pension-savings",
    component: _05eb2cea,
    name: "onboarding-pension-savings"
  }, {
    path: "/onboarding/personal-info",
    component: _7185b62c,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _5bd859bc,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _4946e598,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _6c2be8d4,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _06012835,
    name: "onboarding-suez-info"
  }, {
    path: "/onboarding/tapered-allowance",
    component: _6739660b,
    name: "onboarding-tapered-allowance"
  }, {
    path: "/onboarding/tax-charge-period",
    component: _6e3a512e,
    name: "onboarding-tax-charge-period"
  }, {
    path: "/onboarding/tax-payment-methods",
    component: _1e290d1a,
    name: "onboarding-tax-payment-methods"
  }, {
    path: "/personal/account",
    component: _40fe75e3,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _7de94e6c,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _9971ec9a,
    name: "personal-retirement"
  }, {
    path: "/blog/:slug?",
    component: _585b3da2,
    name: "blog-slug"
  }, {
    path: "/",
    component: _177400c6,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
